import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-scroll";


export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);

  const variants = {
    hidden: { opacity: 0, y: -20 }, // Initial state: invisible and slightly shifted upward
    visible: { opacity: 1, y: 0 }, // Final state: fully visible in position
    exit: { opacity: 0, y: -20 }   // State when removed
  };
  const hoverEffect = {
    whileHover: { scale: 1.1, color: "#f39c12" }, // Slight scale-up and color change
};

// Fade-in animation for navbar on load
const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.8, ease: "easeOut" } },
};

  // Handle mobile menu toggle
  const handleToggle = () => setIsOpen(!isOpen);
  const closeMenu = () => {
    setIsOpen(false);
    setIsServicesDropdownOpen(false);
  };

  const toggleServicesDropdown = (e) => {
    e.preventDefault(); // Prevent Bootstrap default behavior
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  };

  // Close menu on window resize (for responsiveness)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false);
        setIsServicesDropdownOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


//{pageScrollVariants}
  return (
    <motion.nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
      variants={variants}
      initial="hidden"
      animate="visible"
      {...hoverEffect}
    >
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" onClick={handleToggle}>
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">EXIMON</a>
        </div>

        {/* Navbar links (desktop) */}
        <div className={`navbar-collapse ${isOpen ? "in" : "collapse"}`}>
          <ul className="nav navbar-nav navbar-right">
            <li><Link to="features" smooth={true} duration={1000} offset={-70} className="page-scroll"  onClick={closeMenu}>Features</Link></li>
            <li><Link to="about" smooth={true} duration={1000} offset={-70} className="page-scroll" onClick={closeMenu}>About us</Link></li>

            {/* Services Dropdown */}
            <li className={`dropdown ${isServicesDropdownOpen ? "open" : ""}`}>
              <a href="#services" className="page-scroll dropdown-toggle" onClick={toggleServicesDropdown}>
                Services <b className="caret"></b>
              </a>
              <AnimatePresence>
                {isServicesDropdownOpen && (
                  <motion.ul
                    className="dropdown-menu"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3, ease: "easeOut" }}
                    variants={fadeInVariants}
                    
                  >
                    <li><Link to="services" smooth={true} duration={1000} offset={-70} closeMenu="page-scroll" onClick={closeMenu}>EMSS (for Importers Exporters)</Link></li>
                    <li><Link to="icligm" smooth={true} duration={1000} offset={-70} closeMenu="page-scroll" onClick={closeMenu}>ICLIGM (for Maritime Shipping companies)</Link></li>
                    <li><Link to="iclchat" smooth={true} duration={1000} offset={-70} closeMenu="page-scroll" onClick={closeMenu}>ICL ChatBot (GenAI / ML)</Link></li>
                    <li><Link to="iclreact" smooth={true} duration={1000} offset={-70} closeMenu="page-scroll" onClick={closeMenu}>ICL Reacts (Mobile app)</Link></li>
                  </motion.ul>
                )}
              </AnimatePresence>
            </li>

            <li><Link to="portfolio" smooth={true} duration={800} offset={-70} className="page-scroll" onClick={closeMenu}>Gallery</Link></li>
            <li><Link to="testimonials" smooth={true} duration={800} offset={-70} className="page-scroll" onClick={closeMenu}>Testimonials</Link></li>
            <li><Link to="team" smooth={true} duration={800} offset={-70} className="page-scroll" onClick={closeMenu}>Team</Link></li>
            <li><Link to="contact" smooth={true} duration={800} offset={-70} className="page-scroll" onClick={closeMenu}>Contact</Link></li>

          </ul>
        </div>
      </div>
    </motion.nav>
  );
};
