import React from "react";
import Modal  from "react-modal";
import {Helmet} from "react-helmet";
import { useState } from "react";

Modal.setAppElement("#root");

export const Logindemo = (props) => {
  const [selection, setSelection] = useState("");
  const [validUserId, setValidUserId] = useState(false);
  const [validPwd, setValidPwd] = useState(false);
  const [validSelection, setValidSelection] = useState(false);  
  
  const validateUserId = (userId) => {
    // Regular expression for mobile validation
    const uiRegex = /^[ei][0-9]{3,5}$/;
    return uiRegex.test(userId);
  };
  // Input change handler
const handleInputChange = (event) => {
  const userId = event.target.value; // Get the input field value
  const isValid = validateUserId(userId); // Validate the input

  if (isValid) {     
    setValidUserId(true);
//    console.log("Valid User ID:", userId); // Example: Log valid ID
  } else {
    setValidUserId(false);
//    console.error("Invalid User ID:", userId); // Example: Log invalid ID
  }
};

const validatePwd = (pwd) => {
  // Regular expression for password validation
  const pwdRegex = /^[a-zA-Z ]{4}[0-9]{3,4}$/;
  return pwdRegex.test(pwd);
};
// Input change handler
const handlePwdChange = (event) => {
const pwd = event.target.value; // Get the input field value
const isValid2 = validatePwd(pwd); // Validate the input

if (isValid2) {     
  setValidPwd(true);
} else {
  setValidPwd(false);
}
};
// Input change handler Selection
const handleSelection = (event) => {
  const value = event.target.value; // Get the input field value
  
  if (value==='') {     
    setValidSelection(false);
  } else {
    setValidSelection(true);
  }
  };

  return (  
  <div id="logindemo" className="text-center">
      <Helmet>
      <meta charSet="utf-8" lang="en" />
      
      <link rel="canonical" href="https://eximon.com/#logindemo" />
      <meta name="description" content="Software, Database, Cloud, ChatBot services for Importers Exporters and Maritime shipping companies." />
      <meta name="keywords" content="Mobile App Development, App Design, App Development Company, Mobile App Builder, App Prototyping, Mobile UX/UI Design, App Store Optimization, Mobile App Marketing, App Testing, SCMTR, maritime shipping, importers-exporters, Customs, ICEGATE, Cross-Platform Development" />
      <meta property="og:title" content="Sign In - ICL Software Demos" />
    </Helmet>

      <div className="container">
        <div className="section-title">
          <h1>Sign In - ICL Software Demos</h1>
          <h2>Self help portal for ICL-Software services</h2>

       
          <div className="form-group" style={{ width: "300px", margin: "auto" }}>
          <label htmlFor="selection" className="font-weight-bold" style={{ marginTop: "10px", fontSize:"16px" }}>
    Select software:
  </label>

            <select
              id="selection"
              className="form-control"
              value={selection}
              style={{borderColor:validSelection?'green':'#cc5500'}}
              onChange={(e) =>{ 
                setSelection(e.target.value);
                handleSelection(e);
              }
              }
            >
              <option value="">--Select One--</option>
              <option value="ICLIGM">ICLIGM</option>
              <option value="EMSS">EMSS</option>
            </select>
            <label htmlFor="userId" className="font-weight-bold text-light" style={{ marginTop: "10px", fontSize:"16px" }}>
    User ID:
  </label>
            <input type="text" id="userId" className="form-control" placeholder="Enter User ID" onChange={handleInputChange} 
            style={{borderColor:validUserId?'green':'#cc5500'}}
            />

            <label htmlFor="password" className="font-weight-bold text-light" style={{ marginTop: "10px", fontSize:"16px" }}>
    Password:
  </label>

            <input type="password" id="password" className="form-control" placeholder="Enter Password" onChange={handlePwdChange} 
            style={{borderColor:validPwd?'green':'#cc5500'}}
            />
<div className="btn-group">
            <button className="btn btn-custom btn-lg" style={{ marginTop: "20px" }}>Log In</button>
            <button className="btn btn-custom btn-lg" style={{ marginTop: "20px" }}>Sign up</button>            
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};
