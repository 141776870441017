import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';


Modal.setAppElement('#root');

export const Services = (props) => {
  const [data, setData] = useState({ paras: "Loading..." });
  const [dataH, setDataH] = useState({ parasH: "Loading..." });

  const [expanded, setExpanded] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [loadingch, setLoadingch] = useState(true); // Initialize chat loading state

  const videoId = "WWmbk-yP8h0";
  
  const [modalIsOpen, setModalIsOpen] = useState(false);// for right bottom frame
  const [modalIsOpen2, setModalIsOpen2] = useState(false);// for EMSS video frame

//  const navigate = useNavigate();

  


  useEffect(() => {
  
    fetch('https://servguest.eximon.com/ServConI?page=EMSS-React')
      .then((response) => response.text())
      .then((fetchedData) => {
        setLoading(false); // Data is available, set loading to false
        const lines = fetchedData.split('\n');
        const H_lines = fetchedData.split('\n');

        let emssData = {
            A_emprod : "EMSS Product data not found ...",
            B_resaas : "EMSS SaaS data not found ...",        
            C_emhigh : "EMSS Highlights data not found ...",
            D_emusp : "EMSS USP data not found ...",        
            E_emsp : "Some data not found ...",
            F_recloud : "EMSS Cloud data not found ...",        
            G_reinteg : "EMSS Integration Module data not found ...",
            H_relic : "EMSS Licence Module data not found ...",        
          }
          let hData = {
            A_remprodh : "EMSS Product Header data not found ...",                    
            B_resaash : "EMSS SaaS Header data not found ...",        
            C_rehighh : "EMSS Highlights Header data not found ...",        
            D_remusph : "EMSS USP Header data not found ...",        
            E_remsph : "Some data not found ...",        
            F_recloudh : "EMSS Cloud header data not found ...",        
            G_reinth : "EMSS Integration header data not found ...",
            H_relich : "EMSS Licence header data not found ...",        
          }
        for (let line of lines) {
          if (line.trim().startsWith("emprod")) {
            emssData.A_emprod = line.trim().substring(6);
            console.log()
          }         
           if (line.trim().startsWith("resaas")) {
             emssData.B_resaas = line.trim().substring(6);
           }                   
           if (line.trim().startsWith("remhigh")) {
             emssData.C_emhigh = line.trim().substring(7);
           }                   
          if (line.trim().startsWith("remusp")) {
            emssData.D_emusp = line.trim().substring(6);
          }                             
           if (line.trim().startsWith("remsp")) {
             emssData.E_emsp = line.trim().substring(5);
           }                             
           if (line.trim().startsWith("recloud")) {
             emssData.F_recloud = line.trim().substring(7);
           }                             
           if (line.trim().startsWith("reinteg")) {
             emssData.G_reinteg = line.trim().substring(7);
           }                             
           if (line.trim().startsWith("relic")) {
             emssData.H_relic = line.trim().substring(5);
           }                             
        }
        for (let Hline of H_lines) {
          if (Hline.trim().startsWith("remsph")) {
            if(Hline.trim().substring(6)!=null) {
            hData.E_remsph = Hline.trim().substring(6);
            }
          }                             
          if (Hline.trim().startsWith("remusph")) {
            if(Hline.trim().substring(7)!=null) {
            hData.D_remusph = Hline.trim().substring(7);
            }
          }                             
          if (Hline.trim().startsWith("resaash")) {
            if(Hline.trim().substring(7)!=null) {
            hData.B_resaash = Hline.trim().substring(7);
            }
          }                             
          if (Hline.trim().startsWith("rehighh")) {
            if(Hline.trim().substring(7)!=null) {
            hData.C_rehighh = Hline.trim().substring(7);
            }
          }                             
          if (Hline.trim().startsWith("remprodh")) {
            if(Hline.trim().substring(8)!=null) {
            hData.A_remprodh = Hline.trim().substring(8);
            }
          }
          if (Hline.trim().startsWith("recloudh")) {
            if(Hline.trim().substring(8)!=null) {
            hData.F_recloudh = Hline.trim().substring(8);
            }
          }                             
          if (Hline.trim().startsWith("reinth")) {
            if(Hline.trim().substring(6)!=null) {
            hData.G_reinth = Hline.trim().substring(6);
            }
          }                             
          if (Hline.trim().startsWith("relich")) {
            if(Hline.trim().substring(6)!=null) {
            hData.H_relich = Hline.trim().substring(6);
            }
          }                             
        }
        //sorting
        // Create an array of keys in alphabetical order
const sortedKeys = Object.keys(emssData).sort();
const sortedHKeys = Object.keys(hData).sort();

// Create a new object to store the sorted data
const sortedEmssData = {};
const sortedHEmssData = {};

// Iterate through the sorted keys and assign values to the sorted data object
for (const key of sortedKeys) {
  sortedEmssData[key] = emssData[key];
}

// Set the sorted data in the 'paras' property of prevData
setData((prevData) => ({ ...prevData, paras: Object.values(sortedEmssData) }));

// Iterate through the sorted keys and assign values to the sorted data object
for (const key of sortedHKeys) {
  sortedHEmssData[key] = hData[key];
}

// Set the sorted data in the 'paras' property of prevData
setDataH((prevData) => ({ ...prevData, parasH: Object.values(sortedHEmssData) }));

      })
      .catch((error) => {
        console.error('Error fetching data:', error.message);
      });
  },[]);

  // Function to toggle the "Read more" state

  const toggleReadMore = (index) => {
  const updatedStates = [...expanded];
  updatedStates[index] = !expanded[index];
  setExpanded(updatedStates);
   };

  
  // Function to create an HTML element from the string
  const createMarkup = (htmlString) => ({ __html: htmlString });

  const maxLines = 1; // The maximum number of lines to display

  // Function to limit the number of lines displayed
  const truncateText = (text) => {
    if (text) {
      const div = document.createElement('div');
      div.innerHTML = text;
  
      const paras = div.getElementsByTagName('p');
      if (paras.length > maxLines) {
        for (let i = maxLines; i < paras.length; i++) {
          paras[i].style.display = 'none';
        }
      }
  
      return div.innerHTML;
    }
    return text;
  };
 
  return (
    <div id="services" className="text-center">
            <Helmet>
      <meta charSet="utf-8" lang="en" />
      {/* <title>EMSS Software</title> */}
      <link rel="canonical" href="https://eximon.com/#services" />
      <meta name="description" content="EMSS is an Integrated Enterprise Exim Management Software Solution for Export-Import trade including Manufacturers, Corporates, MNCs, Merchant-Exporters & SMEs." />
      <meta name="keywords" content="Enterprise Exim Management Software, Export-Import Trade Solutions, Integrated Trade Management Software, Exim Solutions for Manufacturers, Corporate Trade Management Software, MNC Trade Management Solutions, Merchant-Exporter Software Solutions, SME Trade Management Software, Exim Management for Corporates, Enterprise Export-Import Solutions, Trade Management for MNCs, Software Solutions for Exporters, Import Trade Management Software, Enterprise Trade Solutions, Exim Software for SMEs" />
      <meta property="og:title" content="EMSS Software" />
      <meta property="og:type" content="video.movie" />
      <meta property="og:url" content="https://youtu.be/WWmbk-yP8h0" />
    </Helmet>

      <div className="container">
        <div className="section-title">
          <h1>EMSS Software</h1>
          <h2>
          EMSS is an Integrated Enterprise Exim Management Software Solution for Export-Import trade including Manufacturers, Corporates, MNCs, Merchant-Exporters & SMEs.
          </h2>
        </div>
        <div className="btn-group">                
                <button onClick={()=>setModalIsOpen2(true)}  type="button" className="btn btn-custom btn-lg">
                  EMSS Demo (Intro)
                </button>
                <Modal 
        isOpen={modalIsOpen2} 
        onRequestClose={() => setModalIsOpen2(false)}
        style={{
          content: {
            position: 'absolute',
            top: 'auto',
            left: '10px',
            right: 'auto',
            bottom: '20px',
            width: '70%',
            height: '60%'
          }
        }}
      >
        {loadingch && <div style={{fontWeight:"bold"}}>Please wait loading ...</div>}
        <iframe 
          title="EMSS Demo (Intro)" 
          src={`https://www.youtube.com/embed/${videoId}?rel=0`}  
          allowFullScreen 
          style={{ width: '100%', height: '90%' }} onLoad={()=>setLoadingch(false)}
        />
        {!loadingch && 
                        <button onClick={()=>{setModalIsOpen2(false);setLoadingch(true);}} type="button" className="btn btn-custom btn-lg" >
                  Close
                </button> 
}               
        </Modal>             
        {/* <a href="#logindemo" class="btn btn-custom btn-lg">LogIn Demos</a>  */}
                <button onClick={()=>setModalIsOpen(true)} type="button" className="btn btn-custom btn-lg" >
                  ICL chatBot
                </button>                
                <Modal 
        isOpen={modalIsOpen} 
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          content: {
            position: 'absolute',
            top: 'auto',
            left: 'auto',
            right: '0px',
            bottom: '20px',
            width: '400px',
            height: '500px'
          }
        }}
      >
                {loadingch && <div style={{fontWeight:"bold"}}>Please wait loading ...</div>}
        <iframe 
          title="ICL chatBot" 
          src="https://dtegnbzmkqshu.cloudfront.net" 
          allowFullScreen 
          style={{ width: '100%', height: '90%' }} onLoad={()=>setLoadingch(false)}
        />
{!loadingch && 
                        <button style={{marginTop:"5px"}} onClick={()=>{setModalIsOpen(false);setLoadingch(true);}} type="button" className="btn btn-custom btn-lg" >
                  Close
                </button>                
}
        </Modal>
                                
                </div>
        {/* <div class="video-container">
      <iframe class="video-iframe" 
        src={`https://www.youtube.com/embed/${videoId}?rel=0`} 
        title="EMSS: Is Your EXIM Operation Ready for the Cloud? Find Out in Minutes. Unlock the Future of EXIM." 
        frameborder="0" 
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen loading="lazy" 
      ></iframe>
    </div> */}
        <div className="row" style={{marginTop:'10px'}}>
          {loading
            ? "Loading..." // Display loading message
            : props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <i className={d.icon}></i>
                  <h3>{dataH.parasH[i]}</h3>
                 
{/* Render limited lines with "Read more" link */}
<div
                className={`services-paras ${expanded[i] ? 'expanded' : 'truncated'}`}
                dangerouslySetInnerHTML={createMarkup(
                  expanded[i] ? data.paras[i] : truncateText(data.paras[i])
                )}
              />
              {data.paras[i].length > maxLines && (
                <button onClick={() => toggleReadMore(i)} className="btn-custom">
                  {expanded[i] ? 'Read less' : 'Read more'}
                </button>
              )}
                </div>
              ))
            : "No data available"}
        </div>

      </div>
    </div>
  );
};
